<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row">
            <mat-card>
                <div class="col-md-12">
                    <mat-card-header>
                        <div class="col-md-12 p-3">
                            <mat-card-title class="text-center">
                                <h3>¡EMPAQUETAR ES SENCILLO!</h3>
                                <h5>Sigue estos pasos</h5>
                            </mat-card-title>
                        </div>
                    </mat-card-header>
                    <mat-card-content>
                        <div *ngIf="userObj.tipo!=='q'" class="row mt-2">
                            <div class="col-md-2 my-4"> 
                                <div class="col-md-12 pb-3 text-center">
                                    <button mat-mini-fab color="accent">
                                        1
                                    </button>
                                </div>
                                <div class="col-md-12 pb-3 text-center">
                                    <img mat-card-md-image src="./../../../../../assets/images/start.png">
                                </div>
                                <div class="col-md-12 text-center">
                                    <div class="mat-label"><strong>Para comenzar, escanea el código de la caja.</strong></div>
                                </div>
                            </div>
                            <div class="col-md-2 my-4"> 
                                <div class="col-md-12 pb-3 text-center">
                                    <button mat-mini-fab color="accent">
                                        2
                                    </button>
                                </div>
                                <div class="col-md-12 pb-3 text-center">
                                    <img mat-card-md-image src="./../../../../../assets/images/tuto_step2.png">
                                </div>
                                <div class="col-md-12 text-center">
                                    <div class="mat-label"><strong>En la pantalla se te indica la posición del cajón.</strong></div>
                                </div>
                            </div>
                            <div class="col-md-2 my-4"> 
                                <div class="col-md-12 pb-3 text-center">
                                    <button mat-mini-fab color="accent">
                                        3
                                    </button>
                                </div>
                                <div class="col-md-12 pb-3 text-center">
                                    <img mat-card-md-image src="./../../../../../assets/images/tuto_step3.png">
                                </div>
                                <div class="col-md-12 text-center">
                                    <div class="mat-label"><strong>Identifica el cajón en el rack físico.</strong></div>
                                </div>
                            </div>
                            <div class="col-md-2 my-4"> 
                                <div class="col-md-12 pb-3 text-center">
                                    <button mat-mini-fab color="accent">
                                        4
                                    </button>
                                </div>
                                <div class="col-md-12 pb-3 text-center">
                                    <img mat-card-md-image src="./../../../../../assets/images/tuto_step4.png">
                                </div>
                                <div class="col-md-12 text-center">
                                    <div class="mat-label"><strong>Toma sólo un material del cajón a la vez.</strong></div>
                                </div>
                            </div>
                            <div class="col-md-2 my-4"> 
                                <div class="col-md-12 pb-3 text-center">
                                    <button mat-mini-fab color="accent">
                                        5
                                    </button>
                                </div>
                                <div class="col-md-12 pb-3 text-center">
                                    <img mat-card-md-image src="./../../../../../assets/images/tuto_step5.png">
                                </div>
                                <div class="col-md-12 text-center">
                                    <div class="mat-label"><strong>Escanea el SKU.</strong></div>
                                </div>
                            </div>
                            <div class="col-md-2 my-4"> 
                                <div class="col-md-12 pb-3 text-center">
                                    <button mat-mini-fab color="accent">
                                        6
                                    </button>
                                </div>
                                <div class="col-md-12 pb-3 text-center">
                                    <img mat-card-md-image src="./../../../../../assets/images/tuto_step6.png">
                                </div>
                                <div class="col-md-12 text-center">
                                    <div class="mat-label"><strong>Mete el material a la caja de la línea.</strong></div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="userObj.tipo==='q'" class="row justify-content-center mt-2">
                            <div class="col-md-2 my-4"> 
                                <div class="col-md-12 pb-3 text-center">
                                    <button mat-mini-fab color="accent">
                                        1
                                    </button>
                                </div>
                                <div class="col-md-12 pb-3 text-center">
                                    <img mat-card-md-image src="./../../../../../assets/images/start.png">
                                </div>
                                <div class="col-md-12 text-center">
                                    <div class="mat-label"><strong>Para comenzar, escanea el código de la caja.</strong></div>
                                </div>
                            </div>
                            <div class="col-md-2 my-4"> 
                                <div class="col-md-12 pb-3 text-center">
                                    <button mat-mini-fab color="accent">
                                        2
                                    </button>
                                </div>
                                <div class="col-md-12 pb-3 text-center">
                                    <img mat-card-md-image src="./../../../../../assets/images/cali_tuto_step2.png">
                                </div>
                                <div class="col-md-12 text-center">
                                    <div class="mat-label"><strong>Identifica el estado de la caja.</strong></div>
                                </div>
                            </div>
                            <div class="col-md-2 my-4"> 
                                <div class="col-md-12 pb-3 text-center">
                                    <button mat-mini-fab color="accent">
                                        3
                                    </button>
                                </div>
                                <div class="col-md-12 pb-3 text-center">
                                    <img mat-card-md-image src="./../../../../../assets/images/cali_tuto_step3.png">
                                </div>
                                <div class="col-md-12 text-center">
                                    <div class="mat-label"><strong>Si esta incompleta, la pantalla indica los materiales faltantes.</strong></div>
                                </div>
                            </div>
                            <div class="col-md-2 my-4"> 
                                <div class="col-md-12 pb-3 text-center">
                                    <button mat-mini-fab color="accent">
                                        4
                                    </button>
                                </div>
                                <div class="col-md-12 pb-3 text-center">
                                    <img mat-card-md-image src="./../../../../../assets/images/cali_tuto_step4.png">
                                </div>
                                <div class="col-md-12 text-center">
                                    <div class="mat-label"><strong>Identifica el SKU que te pide y toma uno por uno.</strong></div>
                                </div>
                            </div>
                            <div class="col-md-2 my-4"> 
                                <div class="col-md-12 pb-3 text-center">
                                    <button mat-mini-fab color="accent">
                                        5
                                    </button>
                                </div>
                                <div class="col-md-12 pb-3 text-center">
                                    <img mat-card-md-image src="./../../../../../assets/images/cali_tuto_step5.png">
                                </div>
                                <div class="col-md-12 text-center">
                                    <div class="mat-label"><strong>Escanea el SKU del material tomado.</strong></div>
                                </div>
                            </div>
                            <div class="col-md-2 my-4"> 
                                <div class="col-md-12 pb-3 text-center">
                                    <button mat-mini-fab color="accent">
                                        6
                                    </button>
                                </div>
                                <div class="col-md-12 pb-3 text-center">
                                    <img mat-card-md-image src="./../../../../../assets/images/tuto_step6.png">
                                </div>
                                <div class="col-md-12 text-center">
                                    <div class="mat-label"><strong>Mete el material a la caja de la línea.</strong></div>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-footer>
                        <div class="col-md-12 text-center p-3">
                            <h5>Para salir de esta pantalla, escanea el código <strong>Tutorial</strong></h5>
                        </div>
                    </mat-card-footer>
                </div>
            </mat-card>
        </div>
    </div>
</div>

