<div class="header-wrapper row m-0">
    <div class="left-header horizontal-wrapper ps-0 col-sm-8">
        <ul class="horizontal-menu">
            <li class="level-menu outside">
                <button mat-icon-button class="me-3 sidebar-menu-btn" (click)="menuToggle()"><mat-icon>menu</mat-icon></button>
            </li>
            <li class="level-menu outside">
                <img class="img-fluid img-header" src="./../../../../assets/images/logo_dark.png" alt="">
            </li>
            <li class="level-menu outside">
                <span class="text-uppercase ms-3 badge bg-info"><mat-icon class="align-middle txt-secondary">barcode_reader</mat-icon><strong class="ms-2 txt-light h6 mt-1 pe-1">{{userObj.rack_nombre}}</strong></span>
            </li>
            <li class="level-menu outside" *ngIf="userObj.campana">
                <span class="text-uppercase ms-3 badge bg-light"><mat-icon class="align-middle txt-primary">campaign</mat-icon><strong class="ms-2 txt-dark h6 mt-1 pe-1">{{userObj.campana}}</strong></span>
            </li>
        </ul>
    </div>
    <div class="nav-right col-8 col-sm-2 pull-right right-header p-0 col-sm-4">
        <ul class="nav-menus">
            <li class="profile-nav onhover-dropdown p-0 me-0">
                <div class="media profile-media"><img class="b-r-10" src="./../../../../assets/images/logo_bl_sm.png" alt="">
                    <div class="media-body"><span>{{userObj.rack_nombre}}</span>
                        <p class="mb-0 font-roboto">{{userObj.usuario}}<i class="middle fa fa-angle-down"></i></p>
                    </div>
                </div>
                <ul class="profile-dropdown onhover-show-div">
                    
                    <li><a href="#" (click)="logOut()"><span><mat-icon class="align-middle me-2">logout</mat-icon>Salir</span></a></li>
                </ul>
            </li>
        </ul>
    </div>
</div>