import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-keyevent',
  templateUrl: './keyevent.component.html',
  styleUrls: ['./keyevent.component.scss']
})
export class KeyeventComponent {
  constructor(    
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) { }
}
