import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { LayoutComponent } from './components/shared/layout/layout.component';
import { StationComponent } from './components/station/station.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  {
    path: 'admin', canActivate: [AuthGuard], component: LayoutComponent, data: {expectedRoles: ['Any']},
    children: [      
      { path: '', canActivate: [AuthGuard], component: StationComponent, data: {expectedRoles: ['Any']} },
      { path: 'station', canActivate: [AuthGuard], component: StationComponent, data: {expectedRoles: ['Any']} },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
