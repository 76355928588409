import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  isClientUsr:boolean;

  constructor(private userS:UsersService, ) { 
    this.isClientUsr = (this.userS.getUser().id_rol == environment.role_client_id);
  }

  ngOnInit(): void {
  }

}
