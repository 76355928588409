export const environment = {
    production: false,
    API_URL: "https://qa.api.claw.buhoms.com",
    //API_URL: "http://localhost:4000",
    role_admin_id: 1,
    role_buho_id: 2,
    role_client_id: 3,
    roles: [
      {
        "id": 2,
        "rol": "Buho"
      },{
        "id":3,
        "rol": "Cliente"
      }
    ]
  };