<div>
    <div class="logo-wrapper">
        <a [routerLink]="'dashboard'">
            <img class="img-fluid  for-dark" src="./../../../../assets/images/logo.png" alt="">
        </a>
    </div>
    <nav class="sidebar-main">
        <div id="sidebar-menu">
            <ul class="sidebar-links">
                <div class="simplebar-wrapper">
                    <div class="simplebar-height-auto-observer-wrapper">
                        <div class="simplebar-height-auto-observer"></div>
                    </div>
                    <div class="simplebar-mask">
                        <div class="simplebar-offset">
                            <div class="simplebar-content-wrapper">
                                <div class="simplebar-content">
                                    <li class="sidebar-list">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'station'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">dashboard</mat-icon>Estación</span>
                                        </a>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    </nav>
</div>