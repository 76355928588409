import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserLoginModel } from 'src/app/models/users.model';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterViewInit {
  @ViewChild('f') loginForm!: NgForm;
  @ViewChild('station') station!: ElementRef;
  @ViewChild('username') username!: ElementRef;
  user: UserLoginModel = new UserLoginModel();
  hide = true;
  rememberme = false;
  code: string = '';

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private auth: AuthService,
    private router: Router
  ) { }

  ngAfterViewInit(){
    this.station.nativeElement.focus();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    if (this.auth.isAuthenticated())
      this.router.navigateByUrl('/admin/station');
  }

  login(form: NgForm) {

    if (form.invalid) { return; }

    this.user.name = this.user.name.trim();

    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    
    this.auth.login(this.user).subscribe({
      next: (resp) => {
        Swal.close();
        this.router.navigateByUrl('admin/station');
      },
      error: (err) => {
        this.cleanform(); 
        Swal.fire({
          icon: 'error',
          title: 'Error al autenticar',
          text: 'Estación o usuario no válido',
          timer: 2000,
          showConfirmButton: false
        });
      }
    });
  }

  cleanform() {
    this.user.station = "";
    this.user.name = ""
  }

  @HostListener('window:keypress', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    let stationHasVal = this.user.station?this.user.station!="":false;
    let usernameHasVal = this.user.name?this.user.name!="":false;
    if (event.key === 'Enter') {
      if(stationHasVal&&!usernameHasVal) 
        this.username.nativeElement.focus();
      else if(stationHasVal&&usernameHasVal) {
        this.station.nativeElement.focus(); 
        this.login(this.loginForm);
      }
      
      this.code="";
    } else {
      this.code += event.key;
    }
  }
}
