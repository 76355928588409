import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { UserModel, UserSaveModel } from '../models/users.model';
import jwt_decode from 'jwt-decode';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private main: MainService
  ) { }

  getUser() : any {
    return jwt_decode(localStorage.getItem('token')!);
  }

  getUserList() {
    return this.main.getRequest("/users/show").pipe(
      map((resp: any) => {
        let userList: Array<UserModel> = [];
        if(resp.message == "Success") {          
          resp.data.forEach((e:any) => {
              userList.push(e);
          });
        }     
        return userList;
      })
    );
  }

  deleteUser(id: string) {
    return this.main.deleteRequest(`/users/delete/${ id }`);
  }

  changeStatusUser(id: number, active: number, idServ: number) {
    var userModel = new UserSaveModel();
    userModel.id_user = id;
    userModel.active = active;
    userModel.id_serv = idServ;
    return this.main.postRequest(userModel, "/users/edit/");
  }

  saveUser(userModel: UserSaveModel) {    
    const { passConfirm, id_user, active, ...rest } = userModel;
    return this.main.postRequest(rest, "/users/create");
  }

  editUser(userModel: UserSaveModel, id_user: number) {
    userModel.id_user = id_user;
    const { passConfirm, email, active, ...rest } = userModel;
    return this.main.postRequest(rest, "/users/edit");
  }

  getPermissionList(id_rol: number) {
    return this.main.getRequest("/users/permissions/" + id_rol);
  }

}
