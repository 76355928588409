<div class="page-wrapper compact-wrapper dark-sidebar">
    <div class="page-header" [ngClass]="no_sidebar ? 'no-sidebar' : ''">
        <app-header (menuEvent)="menuEvent($event)"></app-header>
    </div>
    <div class="page-body-wrapper">
        <div class="sidebar-wrapper" [ngClass]="menu_responsive ? 'layout-menu-expanded' : ''" [hidden]="no_sidebar">
            <app-sidebar></app-sidebar>
        </div>
        <div class="page-body" [ngClass]="no_sidebar ? 'no-sidebar' : ''">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
</div>
<div class="bg-overlay" [ngClass]="menu_responsive ? 'active' : ''" (click)="menuEvent(false)"></div>