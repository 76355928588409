export class UserLoginModel {
    name!: string;
    station!: string;
}

export class UserModel {
    id!: number;
    id_rol!: number;
    role!: string;
    role_name!: string;
    email!: string;
    name!: string;
    active!: number;
    ch_pass!: number;
}

export class UserModelMin {
    id!: number;
    id_rol!: number;
    email!: string;
    name!: string;
}

export class UserSaveModel {
    id_user!: number;
    id_rol!: number;    
    id_company!: number;
    id_serv!: number;    
    email!: string;
    pass!: string;
    passConfirm!: string;    
    name!: string;
    active!: number;
    ch_pass!: number;
}